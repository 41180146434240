import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Slideshow from "../components/bb/slideshow"
import Table from "../components/bb/table"
import Button from "../components/bb/button"
import Smartgrid from "../components/bb/smartgrid"
import Card from "../components/bb/card"
import Img from "gatsby-image"
import { css } from "@emotion/react"

const contact_css = css`
  .contact-icon{
      position: relative;
      top: 5px;
    }
  .contact-icon:hover{
    filter:
        contrast(5%)
        invert(1)
        sepia(1)
        saturate(700000%)
        hue-rotate(280deg);
  }
  td:first-of-type{
    width: 75px;
  }
  td p, td img{
    margin: 0;
  }
`

const about_css = css`
  margin: 0 0 20px 0;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 5px 12px rgba(0,0,0,0.5);

  @media screen and (min-width: 412px){
    margin: 7px 30px 12px 0;
    width: 30%;
    float: left;
  }
`;

const h3_css = css`text-align:center; min-height:2.3rem;`

const button_css = css`
  display: block;
  padding: 12px;
  background-color: var(--secondary);
`;

export default function Home({ data }) {
  return (
    <Layout>
      <SEO title="Rae Fox, contemporary romance author of the Bliss Creek Series" />
      <Slideshow imagefilter="carousel" />
      <div id="about">
        <h1>About Rae Fox</h1>
        <div css={about_css}>
          <Img fluid={data.imageSharp.fluid} alt="Rae Fox Portrait" />
        </div>
        <p>
          Rae Fox was born and raised in Texas and currently lives 
          behind the Pine Curtain of East Texas with her husband. 
          Living in their log cabin on the lake was a dream come 
          true, one she truly cherishes. Rae has been writing 
          romance novels for years - never even dreaming she 
          would publish. But with the love of a wonderful man 
          to push her beyond her comfort zone, she took the plunge. 
          Rae loves to read, play tennis, ride a motorcycle, and 
          travel. You can reach Rae on <a href="https://www.facebook.com/raefoxauthor/" target="_blank" rel="noreferrer">Facebook</a>.
        </p>
      </div>

      <div id="books">
        <h1>Books</h1>
        <h2>The Bliss Creek Series</h2>
        <Smartgrid maxcolumns={3}>
          <Card >
            <Link to="/books/#what-might-have-been">
              <Img fluid={data.book1.fluid} />
              <h4 css={h3_css}>What Might Have Been</h4>
              <Button customstyle={button_css} value="Details" to="/books/#what-might-have-been"></Button>
            </Link>
          </Card>
          <Card>
            <Link to="/books/#always-and-forever">
              <Img fluid={data.book2.fluid} />
              <h4 css={h3_css}>Always and Forever</h4>
              <Button customstyle={button_css} value="Details" to="/books/#always-and-forever"></Button>
            </Link>
          </Card>
          <Card>
            <Link to="/books/#the-color-of-love">
              <Img fluid={data.book3.fluid} />
              <h4 css={h3_css}>The Color of Love</h4>
              <Button customstyle={button_css} value="Details" to="/books/#the-color-of-love"></Button>
            </Link>
          </Card>
        </Smartgrid>
      </div>

      <div id="connect" css={contact_css}>
        <h1>Connect with Rae Fox</h1>
        <Table data={[
          [<a style={{borderBottom: "none"}} href="https://www.facebook.com/raefoxauthor/" target="_blank" rel="noreferrer"><img alt="facebook icon" className="contact-icon" width="50px" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTkgMGgtMTRjLTIuNzYxIDAtNSAyLjIzOS01IDV2MTRjMCAyLjc2MSAyLjIzOSA1IDUgNWgxNGMyLjc2MiAwIDUtMi4yMzkgNS01di0xNGMwLTIuNzYxLTIuMjM4LTUtNS01em0tMyA3aC0xLjkyNGMtLjYxNSAwLTEuMDc2LjI1Mi0xLjA3Ni44ODl2MS4xMTFoM2wtLjIzOCAzaC0yLjc2MnY4aC0zdi04aC0ydi0zaDJ2LTEuOTIzYzAtMi4wMjIgMS4wNjQtMy4wNzcgMy40NjEtMy4wNzdoMi41Mzl2M3oiLz48L3N2Zz4=" /></a>, <p><a href="https://www.facebook.com/raefoxauthor/" target="_blank" rel="noreferrer">Connect on Facebook</a></p>],
          [
            <a style={{borderBottom: "none"}} href="https://www.facebook.com/raefoxauthor/" target="_blank" rel="noreferrer">
              <img alt="twitter icon" className="contact-icon" width="50px" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTkgMGgtMTRjLTIuNzYxIDAtNSAyLjIzOS01IDV2MTRjMCAyLjc2MSAyLjIzOSA1IDUgNWgxNGMyLjc2MiAwIDUtMi4yMzkgNS01di0xNGMwLTIuNzYxLTIuMjM4LTUtNS01em0tLjEzOSA5LjIzN2MuMjA5IDQuNjE3LTMuMjM0IDkuNzY1LTkuMzMgOS43NjUtMS44NTQgMC0zLjU3OS0uNTQzLTUuMDMyLTEuNDc1IDEuNzQyLjIwNSAzLjQ4LS4yNzggNC44Ni0xLjM1OS0xLjQzNy0uMDI3LTIuNjQ5LS45NzYtMy4wNjYtMi4yOC41MTUuMDk4IDEuMDIxLjA2OSAxLjQ4Mi0uMDU2LTEuNTc5LS4zMTctMi42NjgtMS43MzktMi42MzMtMy4yNi40NDIuMjQ2Ljk0OS4zOTQgMS40ODYuNDExLTEuNDYxLS45NzctMS44NzUtMi45MDctMS4wMTYtNC4zODMgMS42MTkgMS45ODYgNC4wMzggMy4yOTMgNi43NjYgMy40My0uNDc5LTIuMDUzIDEuMDgtNC4wMyAzLjE5OS00LjAzLjk0MyAwIDEuNzk3LjM5OCAyLjM5NSAxLjAzNy43NDgtLjE0NyAxLjQ1MS0uNDIgMi4wODYtLjc5Ni0uMjQ2Ljc2Ny0uNzY2IDEuNDEtMS40NDMgMS44MTYuNjY0LS4wOCAxLjI5Ny0uMjU2IDEuODg1LS41MTctLjQzOS42NTYtLjk5NiAxLjIzNC0xLjYzOSAxLjY5N3oiLz48L3N2Zz4=" />
            </a>,
            <p><a href="https://twitter.com/raefoxauthor" target="_blank" rel="noreferrer">Connect on Twitter</a></p>
          ],
          [
            <a style={{borderBottom: "none"}} href="http://eepurl.com/cNh51H">
              <img alt="envelope icon" className="contact-icon" width="50px" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTIgMTIuNzEzbC0xMS45ODUtOS43MTNoMjMuOTcxbC0xMS45ODYgOS43MTN6bS01LjQyNS0xLjgyMmwtNi41NzUtNS4zMjl2MTIuNTAxbDYuNTc1LTcuMTcyem0xMC44NSAwbDYuNTc1IDcuMTcydi0xMi41MDFsLTYuNTc1IDUuMzI5em0tMS41NTcgMS4yNjFsLTMuODY4IDMuMTM1LTMuODY4LTMuMTM1LTguMTEgOC44NDhoMjMuOTU2bC04LjExLTguODQ4eiIvPjwvc3ZnPg==" />
            </a>,
            <p><a href="http://eepurl.com/cNh51H" target="_blank" rel="noreferrer">Join the Rae Fox Newsletter</a></p>
          ],
        ]}/>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
    imageSharp(fluid: {originalName: {eq: "raefox.jpg"}}) {
      fluid(maxWidth: 600) {
        ...GatsbyImageSharpFluid
      }
    }
    book2: imageSharp(fluid: {originalName: {eq: "Always_and_Forever_Rae_Fox_small.jpg"}}) {
      fluid(maxWidth: 600) {
        ...GatsbyImageSharpFluid
      }
    }
    book3: imageSharp(fluid: {originalName: {eq: "The_Color_of_Love_Rae_Fox_small.jpg"}}) {
      fluid(maxWidth: 600) {
        ...GatsbyImageSharpFluid
      }
    }
    book1: imageSharp(fluid: {originalName: {eq: "What_Might_Have_Been_Rae_Fox_small.jpg"}}) {
      fluid(maxWidth: 600) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`